<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false" @close="onResetForm('ruleForm')">
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}讲堂</span>
		</div>
		<el-form :model="ruleForm" :rules="isShowDetail ? {} : rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<el-form-item label="讲堂名称" prop="title">
				<el-input v-model.trim="ruleForm.title" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="讲堂类别" prop="lectureTypeDictionaryItemId">
				<el-select v-model="ruleForm.lectureTypeDictionaryItemId" placeholder="请选择" :disabled="isShowDetail">
					<el-option v-for="item in classList" :key="item.id" :label="item.title" :value="item.id"> </el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="培训内容" prop="contentTypeDictionaryItemId">
				<el-select v-model="ruleForm.contentTypeDictionaryItemId" placeholder="请选择" :disabled="isShowDetail">
					<el-option v-for="item in traningContentList" :key="item.id" :label="item.title" :value="item.id"> </el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="讲堂讲师" prop="teacherIds">
				<el-select v-model="ruleForm.teacherIds" multiple placeholder="请选择" :disabled="isShowDetail">
					<el-option v-for="item in teacherList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="讲堂介绍" prop="description">
				<el-input v-model.trim="ruleForm.description" :disabled="isShowDetail" placeholder="请输入" type="textarea" autosize></el-input>
			</el-form-item>
			<el-form-item label="讲堂图片" prop="images">
				<Upload
					ref="uploadImage"
					v-model="ruleForm.images"
					modulePath="LECTURE"
					@uploadImg="uploadImg"
					operator="zhangl"
					:maxCount="1"
					:disabled="isShowDetail"
				></Upload>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button>
			<el-button v-else type="primary" @click="onSave('ruleForm')" :loading="saveLoading">保 存</el-button>
			<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取 消</el-button>
		</span>
	</el-dialog>
</template>

<script>
export default {
	components: {
		Upload: () => import('@/components/Upload/index.vue'),
	},
	data() {
		return {
			createdVisible: false,
			ruleForm: {
				title: '',
				lectureTypeDictionaryItemId: null,
				contentTypeDictionaryItemId: null,
				teacherIds: [],
				images: [],
				description: '',
			},
			teacherList: [],
			classList: [],
			traningContentList: [],
			rules: {
				title: [{ required: true, message: '请输入', trigger: 'blur' }],
				lectureTypeDictionaryItemId: [{ required: true, message: '请输入', trigger: 'blur' }],
				contentTypeDictionaryItemId: [{ required: true, message: '请输入', trigger: 'blur' }],
				teacherIds: [{ required: true, message: '请选择', trigger: 'change' }],
				description: [{ required: true, message: '请输入', trigger: 'blur' }],
				images: [{ required: true, message: '请上传', trigger: 'blur' }],
			},
			attachmentInfoPhoto: {},
			saveLoading: false,
		};
	},
	props: ['isShowDetail'],
	watch: {},
	created() {},
	methods: {
		init(data) {
			this.createdVisible = true;
			this.getAttachmentGroups();
			this.getHallList();
			this.getTraningContentList();
			this.getTeachers();
			if (data.row) {
				this.getDetails(data.row);
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate();
				});
				return;
			}
			// 新增
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		// 获取讲堂类别
		getHallList() {
			this.$http
				.get(this.api.getDictionaryItems + '.json', { params: { dictionaryCode: 'LECTURE_TYPE' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.classList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取培训内容
		getTraningContentList() {
			this.$http
				.get(this.api.getDictionaryItems + '.json', { params: { dictionaryCode: 'LECTURE_CONTENT_TYPE' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.traningContentList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取讲师
		getTeachers() {
			let data = {
				size: 9999,
				current: 1,
				enabled: true,
			};
			this.$http
				.get(this.api.getFamousTeachers + '.json', { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						this.teacherList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		getDetails(row) {
			this.$http
				.get(this.api.getFamousTeacherLectures + `/${row.id}.json`)
				.then((res) => {
					if (res.data && res.data.success) {
						this.setRuleForm(res.data.collection[0]);
					}
				})
				.catch((e) => {});
		},
		// 修改/查看
		setRuleForm(data) {
			console.log(data);
			this.ruleForm = JSON.parse(JSON.stringify(data));
			this.ruleForm.lectureTypeDictionaryItemId = data.lectureTypeDictionaryItem?.id;
			this.ruleForm.contentTypeDictionaryItemId = data.contentTypeDictionaryItem?.id;
			this.ruleForm.teacherIds = data.teachers?.map((v) => v.id) || [];
			this.ruleForm.images = data.attachmentMap['PHOTO']?.map((item) => {
				return {
					title: item.title,
					url: item.link,
					id: item.id,
				};
			});
		},
		// 获取图片附件组
		getAttachmentGroups() {
			this.$http
				.get(this.api.getAttachmentGroups, { params: { subjectTypeCode: 'LECTURE' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.attachmentInfoPhoto = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		onResetForm() {
			this.createdVisible = false;
			this.ruleForm.images = [];
			this.$nextTick(() => {
				this.$refs.ruleForm.resetFields();
			});
		},
		//保存
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					const { images } = this.ruleForm;
					let params = {
						...this.ruleForm,
						attachments: images.map((item) => {
							return {
								link: item.url,
								subjectTypeCode: this.attachmentInfoPhoto.find((item) => item.code == 'PHOTO').subjectTypeCode,
								title: item.title,
								attachmentGroupId: this.attachmentInfoPhoto.find((item) => item.code == 'PHOTO').id,
								mimeType: item.title.split('.')[1],
							};
						}),
					};
					let queryApi = this.ruleForm.id
						? this.$http.put(this.api.getFamousTeacherLectures + `/${this.ruleForm.id}.json`, params)
						: this.$http.post(this.api.getFamousTeacherLectures + '.json', params);
					queryApi
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.saveLoading = false;
								this.onResetForm('ruleForm');
							}
						})
						.catch((e) => {
							this.saveLoading = false;
						});
				}
			});
		},
		uploadImg(dataImg) {
			this.ruleForm.images = dataImg.map((item) => {
				return {
					url: item.url,
					uid: item.uid,
					title: item.title,
				};
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}
	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
	.tagsClass {
		.el-tag {
			margin-right: 16px;
		}
		.el-input {
			width: 88px;
		}
		.el-button--default {
			margin-left: 0;
			padding: 9px 16px;
		}
	}
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
	width: 100px;
	height: 100px;
}
/deep/ .el-upload {
	.el-button--primary {
		border-color: #c4c4c4;
		color: #333;
		background-color: #fff;
		margin-left: 0;
	}
	.is-disabled {
		background-color: #f4f3f3 !important;
		border-color: #e4e7ed !important;
		color: #c0c4cc !important;
	}
}
/deep/ .el-upload--picture-card {
	width: 100px;
	height: 100px;
	line-height: 100px;
}
/deep/ .el-button {
	margin-left: 16px;
}
.hide {
	// display: none;
	/deep/ .el-upload-list {
		.el-icon-close,
		.el-icon-close-tip {
			display: none;
		}
	}
}
/deep/ .el-textarea__inner {
	min-height: 64px !important;
	line-height: 18px !important;
	padding: 5px 12px !important;
}
</style>